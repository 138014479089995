<script>
import EzForm from '@/components/ui/Form/EzForm';
import EzInput from '@/components/ui/Input/EzInput';
import EzButton from '@/components/ui/Button/EzButton';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import { CaseType, DEFAULT_DELIVERY_METHOD, LOADING_KEY } from '@/util/constants';
import { capitalizeWord, convertToSentenceCase } from '@/util/utils';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';

/**
 *
 * @version 1.0.0
 * @since 3.11.0
 */
export default {
  name: 'AccountInfo',
  components: {
    EzForm,
    EzInput,
    EzButton,
    EzDropdown,
    EzLoader,
  },
  data() {
    return {
      formKey: 'provino-settings',
      loadingKeyForm: 'provino-settings-loading',
      slotsData: null,
      selected: null,
      fetchedData: {},
      defaultMethod: DEFAULT_DELIVERY_METHOD,
      deliveryMethod: null,
      transportMode: null,
      selectedMethod: null,
      selectedTransport: null,
      selectedAttachment: null,
      isEnabledAction: false,
      apiKeyVal: '',
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapGetters('entities/users', [
      'isDistributorProvinoConnected',
    ]),
    attachmentsData() {
      const types = ['delivery_order', 'invoice'];
      return types.map(ty => ({
        id: ty,
        name: convertToSentenceCase(ty, true, CaseType.SNAKE_CASE),
      }));
    },
    checkIsSomeLoading() {
      return this.isSomeLoading([
        LOADING_KEY.JOKER,
      ]);
    },
    isEnabled() {
      return this.selectedMethod === 'delivery'
        ? (this.selectedMethod
          && this.apiKeyVal
          && this.selectedTransport
          && this.selectedAttachment)
        : this.apiKeyVal;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorFetchProvinoSetup',
      'distributorFetchProvinoEnums',
    ]),
    ...mapActions('entities/users', ['initDistributor']),
    ...mapMutations('loading', ['SET_LOADING', 'CLEAR_LOADING']),
    selectDeliveryMethod(mth) {
      if (mth.key !== this.defaultMethod) {
        this.selectedTransport = null;
      } else {
        this.selectedTransport = this.fetchedData.transportMode;
      }
      this.selectedMethod = mth.key;
      this.$refs.form.clearErrors(['EzDropdown', 'EzInput']);
    },
    selectTransport(trn) {
      this.selectedTransport = trn.key;
      this.$refs.form.clearErrors(['EzDropdown', 'EzInput']);
    },
    selectAttachment(att) {
      this.selectedAttachment = att.id;
      this.$refs.form.clearErrors(['EzDropdown', 'EzInput']);
    },
    onApiKeyChange(e) {
      this.apiKeyVal = e;
    },
    async onSubmitSuccess() {
      await this.initDistributor();
      flash.success({
        title: `Successfully update ${this.$t('provino.name')} settings!`,
        message: `You are successfully updated ${this.$t('provino.name')} settings.`,
      });
    },
  },
  async created() {
    this.SET_LOADING({ [LOADING_KEY.JOKER]: true });
    if (this.isDistributorProvinoConnected) {
      const { data: { data } } = await this.distributorFetchProvinoSetup();
      this.fetchedData = data;
    }

    const { data: { data: provinoEnums } } = await this.distributorFetchProvinoEnums();
    const { deliveryMethod, transportMode } = provinoEnums;
    this.deliveryMethod = deliveryMethod
      .map(mth => ({ key: mth.key, label: mth.label, name: capitalizeWord(mth.key) }));
    this.transportMode = transportMode
      .map(trm => ({ key: trm.key, label: trm.label, name: capitalizeWord(trm.key) }));

    this.selectedMethod = this.fetchedData.deliveryMethod || this.defaultMethod;
    this.apiKeyVal = this.fetchedData.apiKey;
    this.selectedTransport = this.fetchedData.transportMode;
    this.selectedAttachment = this.fetchedData.attachmentType;

    this.CLEAR_LOADING({ key: LOADING_KEY.JOKER });
  },
};
</script>

<template>
  <div class="container--small">
    <ez-form
      :formKey="formKey"
      :loading-key="loadingKeyForm"
      action="/distributor/provino"
      method="put"
      @success="onSubmitSuccess"
      ref="form"
    >
      <ez-input
        :formKey="formKey"
        name="apiKey"
        label="Account Key"
        placeholder="Enter Account Key"
        :value="apiKeyVal"
        class="mb-8"
        @onChange="onApiKeyChange"
      />
      <ez-dropdown
        name="deliveryMethod"
        label="Delivery Type"
        class="mb-8"
        :data="deliveryMethod || []"
        :selected="selectedMethod"
        is-full-width
        :formKey="formKey"
        idKey="key"
        placeholder="Select Delivery Method"
        @change="selectDeliveryMethod"
      />
      <ez-dropdown
        v-if="selectedMethod === defaultMethod"
        name="transportMode"
        label="Transport Mode"
        class="mb-12"
        :data="transportMode || []"
        :selected="selectedTransport || ''"
        is-full-width
        :formKey="formKey"
        idKey="key"
        placeholder="Select Transport Mode"
        @change="selectTransport"
      />
      <ez-dropdown
        name="attachmentType"
        label="Attachment"
        class="mb-12"
        :data="attachmentsData || []"
        :selected="selectedAttachment || ''"
        is-full-width
        :formKey="formKey"
        placeholder="Select Attachment Type"
        @change="selectAttachment"
      />
      <template #buttons>
        <ez-button class="mt-24" form-type="submit" :disabled="!isEnabled">
          Save Changes
        </ez-button>
      </template>
    </ez-form>
    <ez-loader :show="checkIsSomeLoading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
.documents-checkboxes {
  display: flex;
}
.attachments-label {
  margin-bottom: 8px;
  color: #8790A3;
  @include font-size(12px);
}

</style>

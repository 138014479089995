<template>
  <div class="admin-single-page">
    <header class="admin-single-page__header">
      <div class="admin-single-page__breadcrumbs">
        <slot name="breadcrumbs"></slot>
      </div>
      <div class="admin-single-page__title">
        <div class="admin-single-page__title-text">
          <slot name="title"></slot>
        </div>
        <div class="admin-single-page__title-actions">
          <slot name="actions"></slot>
        </div>
      </div>
      <div class="admin-single-page__navigation">
        <slot name="navigation"></slot>
      </div>
    </header>
    <section class="admin-single-page__content">
      <transition name="fade" mode="out-in">
        <router-view :entity="entity"></router-view>
      </transition>
      <slot></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SinglePage',
  props: {
    entity: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
  $header-border-color: #F3F5FA;
  $tab-text-color: #6C7995;
  $active-tab-border-color: #4D7CFE;
  $active-tab-text-color: #252631;

  .admin-single-page {
    @include font-size(14px);
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    &__header {
      padding: 0 3rem;
    }
    &__breadcrumbs {
      @include font-size(12px, 14px);
      font-weight: 400;
      margin: 1rem 0;
      a, span {
        color: $tab-text-color;
        text-decoration: none;
        :deep() svg {
          margin-right: .25rem;
        }
      }
      span {
        margin: 0 .375rem;
      }
    }
    &__navigation {
      position: relative;
      border-bottom: .125rem solid $header-border-color;
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        position: relative;
        bottom: -.125rem;

        li {
          & + li {
            margin-left: 1.5rem;
          }
          a, .navigation-link {
            font-weight: 400;
            display: block;
            color: $tab-text-color;
            text-decoration: none;
            padding: .625rem 0;
            border-top: .125rem solid rgba(255, 255, 255, 0);
            border-bottom: .125rem solid rgba(255, 255, 255, 0);
            &.router-link-exact-active {
              border-bottom: .125rem solid $active-tab-border-color;
              color: $active-tab-text-color;
            }
          }

        }
      }
    }
    &__title {
      display: flex;
      justify-content: space-between;
      padding-bottom: .75rem;

      &-text { width: 70%; }
      :deep() .entity-info {
        h1 {
          @include font-size(24px);
          font-weight: 500;
          margin: 0;
        }
      }
      :deep() .status-badge,
      :deep() .status {
        margin-left: .5rem;
      }
      &-actions > :deep() * + * { margin-left: .75rem; }
    }
    &__content {
      flex: 1 1 auto;
      padding: 1.5rem 3rem 5.5rem 3rem;
    }

    :deep() .invitation-button {
      margin-right: .75rem;
      svg {
        margin-right: .5rem;
      }
    }
  }
</style>

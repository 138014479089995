<template>
  <div
    :class="{
      'input-group': true,
      'ez-dropdown': true,
      'ez-dropdown__hover': !expanded,
      'ez-dropdown__expanded': expanded,
      'ez-dropdown__disabled': disabled || readonly,
      'ez-dropdown--has-error': hasErrorMessage,
      'ez-dropdown--block': isFullWidth,
    }"
  >
    <label class="ez-dropdown__label" :for="id" v-if="label">{{ label }}</label>
    <div
      class="ez-dropdown__input-wrapper"
      tabindex="0"
      @click="toggleExpand"
      @keypress="onKeypress"
      :title="tooltip || (disabled && 'You might not have permission to edit this field.')"
      :disabled="disabled"
      :data-cy="dataCy"
    >
      <div
        :class="{
          'ez-dropdown__display': true,
          'ez-dropdown__display-empty': !value[idKey],
        }"
      >
        {{ value.name || placeholder }}
      </div>
      <input type="hidden" :id="id" :name="name" :value="value[idKey]" :disabled="disabled" />
      <button type="button" tabindex="-1">
        <font-awesome-icon v-if="expanded" icon="angle-up" />
        <font-awesome-icon v-else icon="angle-down" />
      </button>
    </div>
    <div class="ez-dropdown__error" v-if="hasErrorMessage">{{ error.message }}</div>
    <div class="ez-dropdown__options">
      <ul ref="list" v-if="data.length && expanded">
        <li
          v-for="(item, idx) in data"
          :key="item[idKey]"
          :data-cy="dataCy ? `${dataCy}-${idx}` : ''"
        >
          <div
            :class="{
              'ez-dropdown__item': !parentsNotSelectable || item.selectable,
              'ez-dropdown__item--has-text': !!getText(item),
              'ez-dropdown__item-selected': item[idKey] == value[idKey],
            }"
            :data-value="item[idKey]"
            @click="(!parentsNotSelectable || item.selectable) && selectValue(item[idKey])"
          >
            <div class="ez-dropdown__item-label">{{ item.name }}</div>
            <p v-if="getText(item)" class="ez-dropdown__item-text">{{ getText(item) }}</p>
          </div>
          <ul v-if="(item.children || []).length">
            <li
              v-for="(child, deepIdx) in item.children"
              :key="child[idKey]"
              :data-cy="dataCy ? `${dataCy}-${idx}-${deepIdx}` : ''"
            >
              <div
                :class="{
                  'ez-dropdown__item': true,
                  'ez-dropdown__item--has-text': !!getText(item),
                  'ez-dropdown__item-selected': item[idKey] == child[idKey],
                }"
                :data-value="child[idKey]"
                @click="selectValue(child[idKey])"
              >
                <div class="ez-dropdown__item-label">{{ child.name }}</div>
                <p v-if="getText(item)" class="ez-dropdown__item-text">{{ getText(item) }}</p>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import uuid from 'uuid/v4';

export default {
  /**
   * Name is explicitly needed here because it will be used to
   * dynamically restart inputs inside ezForm
   * @see src/components/ui/Form/EzForm.vue
   */
  name: 'EzDropdown',
  props: {
    formKey: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    /**
     * Tooltip text, describes input's purpose or state on hover
     */
    tooltip: {
      required: false,
      type: String,
    },
    selected: {
      type: [Number, String],
      required: false,
    },
    /**
     * If the dropdown should be type `block` and the 100% `width`.
     */
    isFullWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    textKey: {
      type: String,
      required: false,
      default: 'text',
    },
    idKey: {
      type: String,
      required: false,
      default: 'id',
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    parentsNotSelectable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      id: '',
      expanded: false,
      currentId: this.selected ?? -1,
    };
  },
  computed: {
    value() {
      if (this.currentId === -1) {
        return (!this.placeholder && this.data[0]) || {};
      }

      let selectedItem = {};

      this.data.forEach(item => {
        if (selectedItem && selectedItem[this.idKey]) {
          return;
        }

        if (item[this.idKey] === this.currentId) {
          selectedItem = item;
          return;
        }

        if ((item.children || []).length) {
          selectedItem = item.children.find(child => child[this.idKey] === this.currentId);
        }
      });

      return selectedItem || {};
    },
    error() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    hasErrorMessage() {
      return (
        this.error &&
        typeof this.error === 'object' &&
        Object.prototype.hasOwnProperty.call(this.error, 'message')
      );
    },
  },
  watch: {
    selected(value) {
      this.selectValue(value, false);
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERROR']),
    selectValue(id, emitChange = true) {
      this.currentId = id;
      this.expanded = false;

      if (emitChange && this.value !== undefined) {
        /**
         * When input value is changed.
         * @event change
         * @type String
         */
        this.$emit('change', this.value);
      }
    },
    toggleExpand() {
      if (this.disabled || this.readonly) {
        return;
      }

      this.expanded = !this.expanded;
    },
    getText(row) {
      return row[this.textKey];
    },
    close() {
      if (this.disabled) {
        return;
      }

      this.expanded = false;
    },
    reset() {
      this.selectValue(-1, false);
    },
    clearErrors() {
      this.errors.clear();
      this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
    },
    documentClick(e) {
      if (!this.$el.contains(e.target)) {
        this.close();
      }
    },
    onKeypress(event) {
      if (event.key === 'Enter') {
        this.toggleExpand();
      }
    },
    nextItem() {
      // TODO: highlight item on when arrow is pressed
    },
  },
  mounted() {
    this.id = uuid();

    window.addEventListener('click', this.documentClick);
    window.addEventListener('keyup', this.nextItem);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.documentClick);
    window.addEventListener('keyup', this.nextItem);
  },
};
</script>

<style scoped lang="scss">
$label-color: $color-gray-6C;
$label-font-size: 12px;

$background-color: #ffffff;
$border-color: #dee1e4;
$border-radius: 0.1875rem;
$input-height: 2.25rem;
$input-disabled-bg-color: #e8ecf7;
$input-disabled-color: #b4b8c2;
$selected-item-background: $color-gray-F5;
$placeholder-color: $color-gray-6C;
$input-border-error-color: #ea4b5d;

$padding: 0 0.75rem;

.ez-dropdown {
  flex-shrink: 0;
  @include font-size(14px);
  border-radius: $border-radius;
  max-width: 12.5rem;
  width: 100%;

  &__expanded {
    .ez-dropdown__input-wrapper {
      border-color: $color-primary-blue;

      button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &__input-wrapper {
    background-color: $color-gray-F5;
    height: $input-height;
    display: flex;
    align-items: center;
    min-width: 100px;
    border-radius: $border-radius;
    border: 0.125rem solid rgba(255, 255, 255, 0);
    cursor: pointer;
    outline: none;

    button {
      @extend %button-reset;
      padding: 0 0.75rem;
      outline: none;
      border: none;
      @include border-right-radius($border-radius);
    }

    svg {
      color: $placeholder-color;
    }
  }

  &__display {
    flex: 1 1 auto;
    @include border-left-radius($border-radius);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: $padding;
    padding-right: 0;
  }

  &__display-empty {
    color: $placeholder-color;
  }

  &__label {
    display: block;
    margin-bottom: 0.5rem;
    color: $label-color;
    @include font-size($label-font-size);
    font-weight: 500;
  }

  &__options {
    position: relative;
    z-index: 30;

    > ul {
      position: absolute;
      top: 0.375rem;
      max-height: 20em;
      overflow-y: auto;
      border: 1px solid #dee1e4;
      border-radius: $border-radius;
      box-shadow: 0 8px 6px -4px rgba(0, 0, 0, 0.12);
    }

    ul {
      padding: 0;
      margin: 0;
      display: block;
      width: 100%;
      list-style: none;
      background: $background-color;

      li {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:first-child {
          @include border-top-radius($border-radius);
        }

        &:last-child {
          @include border-bottom-radius($border-radius);
          border-bottom: 0;
        }

        &.ez-dropdown__item-selected {
          background-color: $selected-item-background;
        }

        &.ez-dropdown__item--has-text {
          @include font-size(14px, 16px);
          font-weight: 400;
          padding: $padding;
          display: flex;
          flex-direction: column;
          height: auto;
        }
      }

      ul .ez-dropdown__item {
        padding-left: 1.875rem;
      }
    }
  }

  &__item {
    padding: px-to-rem(10px) 1rem;

    &:hover {
      cursor: pointer;
      background-color: $selected-item-background;
    }
  }

  &__item-label {
    margin-bottom: px-to-rem(4px);
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  &__item-text {
    @include font-size(12px, 16px);
    color: $color-gray-6C;
    margin: 0;
    white-space: normal;
    padding-bottom: 0.25rem;
  }

  &__disabled {
    .ez-dropdown__input-wrapper,
    .ez-dropdown__display,
    button {
      background-color: $input-disabled-bg-color;
      color: $input-disabled-color;
      cursor: not-allowed;
    }

    .ez-dropdown__input-wrapper {
      svg {
        color: $input-disabled-color;
      }
    }
  }

  &--has-error {
    .ez-dropdown__input-wrapper {
      border: px-to-rem(1px) solid $input-border-error-color;
    }
  }

  &--block {
    display: block;
    max-width: 100%;
  }

  &__error {
    margin-top: 0.5rem;
    color: $input-border-error-color;
    @include font-size(12px);
  }
}
.input-group.ez-dropdown__hover :hover {
  background-color: #e9ebf2;
  transition: background-color 0.2s ease-in-out;
}
.ez-dropdown__label {
  background-color: #fff !important;
}
</style>

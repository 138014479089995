<script>
import Page from '@/views/admin/SinglePage';
import VStatusBadge from '@/components/v3/elements/VStatusBadge';
import { mapActions, mapGetters } from 'vuex';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown';
import EzButton from '@/components/ui/Button/EzButton';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal';
import { LOADING_KEY } from '@/util/constants';

/**
 *
 * @version 1.0.0
 * @since 3.11.0
 */
export default {
  name: 'Index',
  components: {
    Page,
    VStatusBadge,
    EzButton,
    EzButtonDropdown,
    EzConfirmationModal,
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapGetters('entities/users', [
      'isDistributorProvinoConnected',
    ]),
    isLoading() {
      return this.getLoading(LOADING_KEY.DISCONNECT_FROM_PROVINO);
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'distributorProvinoDisconnect',
    ]),
    async confirmProvinoDisconnect() {
      try {
        await this.distributorProvinoDisconnect({ loadingKey: LOADING_KEY.DISCONNECT_FROM_PROVINO });
        this.$refs.provinoDisconnectModal.close();
        await this.$router.push({
          name: 'distributor-integrations',
          params: {
            flash: {
              title: `You have successfully disconnected from ${this.$t('provino.name')}.`,
            },
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
};
</script>

<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'distributor-integrations' }">
        <font-awesome-icon icon="arrow-left"/>
        Back to Supplier Settings
      </router-link>
    </template>
    <template #title>
      <div class="u-flex-center logo">
        <img src="@/assets/logo-provino.png" width="32" height="32" alt="Provino Logo"/>
        <h2 class="m-0 ml-8 provino-title">{{ $t('provino.name') }}</h2>
        <v-status-badge type="green" v-if="isDistributorProvinoConnected">Connected</v-status-badge>
        <v-status-badge type="gray" v-else>Not Connected</v-status-badge>
      </div>
    </template>
    <template #actions>
      <div>
        <ez-button-dropdown v-if="isDistributorProvinoConnected" buttonType="secondary">
          <template #icon>
            <font-awesome-icon icon="ellipsis-h"/>
          </template>
          <template #dropdown>
            <ez-button v-open="'provinoDisconnectModal'" type="red-link">
              Disconnect
            </ez-button>
          </template>
        </ez-button-dropdown>
      </div>
    </template>

    <template #navigation>
      <ul>
        <li>
          <router-link :to="{ name: 'distributor-provino-account' }">
            Account Info
          </router-link>
        </li>
      </ul>
    </template>

    <ez-confirmation-modal ref="provinoDisconnectModal" type="red">
      <template #title>Disconnect from {{ $t('provino.name') }}?</template>
      <template #content>
        <div class="xero-description">
          You’re about to disconnect your {{ oezPlatformName }} account from {{ $t('provino.name') }}.
        </div>
      </template>
      <template #footer>
        <ez-button v-close="'xeroDisconnectModal'" type="link">
          Cancel
        </ez-button>
        <ez-button :is-loading="isLoading" @click="confirmProvinoDisconnect" type="red">
          Disconnect
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </page>
</template>

<style lang="scss" scoped>
.logo {
  .provino-title {
    @include font-size(24px);
    font-weight: 500;
  }
  img {
    border: 1px solid #E1E5ED;
    border-radius: 50%;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'input-group': true,
    'ez-dropdown': true,
    'ez-dropdown__hover': !_vm.expanded,
    'ez-dropdown__expanded': _vm.expanded,
    'ez-dropdown__disabled': _vm.disabled || _vm.readonly,
    'ez-dropdown--has-error': _vm.hasErrorMessage,
    'ez-dropdown--block': _vm.isFullWidth,
  }},[(_vm.label)?_c('label',{staticClass:"ez-dropdown__label",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"ez-dropdown__input-wrapper",attrs:{"tabindex":"0","title":_vm.tooltip || (_vm.disabled && 'You might not have permission to edit this field.'),"disabled":_vm.disabled,"data-cy":_vm.dataCy},on:{"click":_vm.toggleExpand,"keypress":_vm.onKeypress}},[_c('div',{class:{
        'ez-dropdown__display': true,
        'ez-dropdown__display-empty': !_vm.value[_vm.idKey],
      }},[_vm._v(" "+_vm._s(_vm.value.name || _vm.placeholder)+" ")]),_c('input',{attrs:{"type":"hidden","id":_vm.id,"name":_vm.name,"disabled":_vm.disabled},domProps:{"value":_vm.value[_vm.idKey]}}),_c('button',{attrs:{"type":"button","tabindex":"-1"}},[(_vm.expanded)?_c('font-awesome-icon',{attrs:{"icon":"angle-up"}}):_c('font-awesome-icon',{attrs:{"icon":"angle-down"}})],1)]),(_vm.hasErrorMessage)?_c('div',{staticClass:"ez-dropdown__error"},[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c('div',{staticClass:"ez-dropdown__options"},[(_vm.data.length && _vm.expanded)?_c('ul',{ref:"list"},_vm._l((_vm.data),function(item,idx){return _c('li',{key:item[_vm.idKey],attrs:{"data-cy":_vm.dataCy ? `${_vm.dataCy}-${idx}` : ''}},[_c('div',{class:{
            'ez-dropdown__item': !_vm.parentsNotSelectable || item.selectable,
            'ez-dropdown__item--has-text': !!_vm.getText(item),
            'ez-dropdown__item-selected': item[_vm.idKey] == _vm.value[_vm.idKey],
          },attrs:{"data-value":item[_vm.idKey]},on:{"click":function($event){(!_vm.parentsNotSelectable || item.selectable) && _vm.selectValue(item[_vm.idKey])}}},[_c('div',{staticClass:"ez-dropdown__item-label"},[_vm._v(_vm._s(item.name))]),(_vm.getText(item))?_c('p',{staticClass:"ez-dropdown__item-text"},[_vm._v(_vm._s(_vm.getText(item)))]):_vm._e()]),((item.children || []).length)?_c('ul',_vm._l((item.children),function(child,deepIdx){return _c('li',{key:child[_vm.idKey],attrs:{"data-cy":_vm.dataCy ? `${_vm.dataCy}-${idx}-${deepIdx}` : ''}},[_c('div',{class:{
                'ez-dropdown__item': true,
                'ez-dropdown__item--has-text': !!_vm.getText(item),
                'ez-dropdown__item-selected': item[_vm.idKey] == child[_vm.idKey],
              },attrs:{"data-value":child[_vm.idKey]},on:{"click":function($event){return _vm.selectValue(child[_vm.idKey])}}},[_c('div',{staticClass:"ez-dropdown__item-label"},[_vm._v(_vm._s(child.name))]),(_vm.getText(item))?_c('p',{staticClass:"ez-dropdown__item-text"},[_vm._v(_vm._s(_vm.getText(item)))]):_vm._e()])])}),0):_vm._e()])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }